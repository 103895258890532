/* eslint-disable angular/on-watch */

angular
  .module('slf', ['ui.router', 'ngCookies', 'ngAnimate', 'duScroll'])
  .run(function ($transitions, $state, $rootScope, UserAuth, ApiSocket, Rooms, Top10, ModalWindow, $window) {
    $rootScope.$watch(function () {
      return UserAuth.getAuthenticatedUser();
    }, function () {
      $rootScope.isAuthenticated = UserAuth.isAuthenticated();
      $rootScope.user = UserAuth.getAuthenticatedUser();
    }, true);

    // instantiate rooms service, so room list is up to date when leaving the splash screen
    Rooms; // eslint-disable-line no-unused-expressions
    Top10; // eslint-disable-line no-unused-expressions

    var modalWait;

    ApiSocket.on('disconnect', function () {
      $state.go('game.lobby', {}, {custom: {force: true}});
      modalWait = ModalWindow.wait(['Leider ist die Verbindung zum Server abgebrochen. Du wurdest daher aus dem aktuellen Spiel entfernt.', 'Bitte warte, bis die Verbindung wiederhergestellt ist...']);
    });

    ApiSocket.on('connect', function () {
      if (modalWait) {
        modalWait();
      }
    });

    function onResize() {
      if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        document.getElementById('wrapper').classList.add('wrapper-desktop-zoom');
      } else if (window.self !== window.top) {
        var wrapper = document.getElementById('wrapper');
        var actualWidth = window.innerWidth;
        var actualHeight = window.innerHeight;
        var minWidth = 900;
        var minHeight = 657;
        var ratio = Math.min(actualWidth / minWidth, actualHeight / minHeight);
        wrapper.style.webkitTransform = 'scale(' + ratio + ') translate(-50%, -50%)';
        wrapper.style.webkitTransformOrigin = '0 0';
        wrapper.style.transform = 'scale(' + ratio + ') translate(-50%, -50%)';
        wrapper.style.transformOrigin = '0 0';
      }
    }

    $window.addEventListener('resize', onResize);
    $window.addEventListener('orientationchange', onResize);

    try {
      $rootScope.isFramed = $window.self !== $window.top;
    } catch (e) {
      $rootScope.isFramed = true;
    }

    if (location.hostname !== 'localhost' && location.hostname !== '192.168.178.80' && !$rootScope.isFramed) {
      $window.location.href = 'https://spiele.rtl.de/denk-spiele/stadt-land-fluss.html';
    }

    onResize();
  });
